import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6499c7ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-module-title" }
const _hoisted_2 = { class: "forget-pwd-tips" }
const _hoisted_3 = { class: "forget-pwd-tips" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mail_outlined = _resolveComponent("mail-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_klook_phone_input = _resolveComponent("klook-phone-input")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_klook_icon = _resolveComponent("klook-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    class: "forget-pwd",
    model: _ctx.form,
    rules: _ctx.formRules
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('75620')), 1),
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.form.login_type,
        "onUpdate:activeKey": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.login_type) = $event)),
        animated: false,
        onChange: _ctx.onLoginTypeChanged
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            tab: _ctx.$t('75597'),
            key: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, { name: "username" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.form.username,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
                    size: "large",
                    placeholder: _ctx.$t('75602')
                  }, {
                    suffix: _withCtx(() => [
                      _createVNode(_component_mail_outlined)
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }),
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('75621')), 1)
            ]),
            _: 1
          }, 8, ["tab"]),
          _createVNode(_component_a_tab_pane, {
            tab: _ctx.$t('75598'),
            key: "phone"
          }, {
            default: _withCtx(() => [
              (_ctx.form.login_type === 'phone')
                ? (_openBlock(), _createBlock(_component_a_form_item, {
                    key: 0,
                    prop: "username"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_klook_phone_input, {
                        value: _ctx.form.username,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.username) = $event)),
                        size: "large",
                        set: _ctx.set
                      }, null, 8, ["value", "set"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('75622')), 1)
            ]),
            _: 1
          }, 8, ["tab"])
        ]),
        _: 1
      }, 8, ["activeKey", "onChange"]),
      _withDirectives(_createVNode(_component_a_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            size: "large",
            type: "primary",
            loading: _ctx.isLoading,
            block: "",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('75592')), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ]),
        _: 1
      }, 512), [
        [_vShow, ['email', 'phone'].includes(_ctx.form.login_type)]
      ]),
      _createVNode(_component_router_link, {
        to: "/login",
        class: "back-login-link"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_klook_icon, {
            type: "left",
            size: 24
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('75624')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}