
import { useRoute, useRouter } from 'vue-router'
import { defineComponent, onBeforeMount, reactive, ref, toRefs } from 'vue'
import { accountRules } from '@/common/js/form-rule/index'
import { URLS } from '@/common/js/constant/index'
import { post } from '@/plugins'
import { FormInstance, message } from 'ant-design-vue'
import KlookPhoneInput from '@/components/phone-input.vue'
import { MailOutlined } from '@ant-design/icons-vue'
import KlookIcon from '@/components/icon.vue'
import { useI18n } from 'vue-i18n'
import { specialCode } from '@/plugins/axios'

export default defineComponent({
  components: {
    KlookIcon,
    KlookPhoneInput,
    MailOutlined
  },

  setup() {
    const router = useRouter()
    const formRef = ref<FormInstance | any>()
    const { t } = useI18n({})
    const route = useRoute()

    const state = reactive({
      formRules: {},
      form: {
        login_type: 'email',
        username: '',
        err_message: '',
        err_type: ''
      },
      isLoading: false,
      set: false
    })

    onBeforeMount(() => {
      const query: any = route.query
      state.form = {
        ...state.form,
        login_type: query.type || state.form.login_type,
        username: query.username || state.form.username
      }
      if (query.type && query.type === 'phone' && query.username) {
        state.set = true
      }
      bindRules()
    })

    const bindRules = () => {
      state.formRules = accountRules.call(null, state.form)
    }
    const onLoginTypeChanged = (val: string) => {
      state.form = {
        ...state.form,
        login_type: val,
        username: ''
      }

      bindRules()
      formRef.value.resetFields()
    }

    const submit = () => {
      formRef.value.validate().then(() => {
        state.isLoading = true
        post(URLS.findPassword, {
          username: state.form.username
        })
          .then(() => {
            message.success(t('76173') as string)
            router.push({ name: 'login' })
          })
          .catch((err) => {
            setErrMessage(err)
          })
          .finally(() => {
            state.isLoading = false
          })
      })
    }
    // 设置错误信息
    const setErrMessage = (res: any) => {
      if (specialCode[res.code]) {
        switch (specialCode[res.code]) {
          case '用户不存在':
            state.form.err_message = res.message
            state.form.err_type = 'username'
            formRef.value.validateFields()
            break
        }
        setTimeout(() => {
          state.form.err_message = ''
          state.form.err_type = ''
        }, 1000)
      }
    }

    return {
      ...toRefs(state),
      formRef,
      submit,
      onLoginTypeChanged
    }
  }
})
